import { Reducer } from "redux"

export interface IMessages {
	name: string
	message: string
	date: number
}

export interface IQuestion {
	id: number
	date: number
	account: string
	name: string
	place: string
	phone: string
	photo: string
	question: string
	state: number
	dialog: IMessages[]
}

interface IAddAnswer {
	id: number
	message: string
}

export type questionState = {
	data: IQuestion[]
}

const defaultState: questionState = {
	data: [
		{
			id: 1695001022473,
			date: 1695001022473,
			account: "accout",
			name: "Котов Данил Алексеевич",
			place: "1000 ИНСТРУМЕНТОВ",
			phone: "+7 999 999 99 99",
			photo: "",
			question: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.",
			state: 2,
			dialog: [{ name: "АДМИНИСТРАТОР", message: "Всё окей, всё починим", date: 1695001022473 }],
		},
		{
			id: 1696101022473,
			date: 1696101022473,
			account: "accout",
			name: "Котов Данил Алексеевич",
			place: "1000 ИНСТРУМЕНТОВ",
			phone: "+7 999 999 99 99",
			photo: "",
			question: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.",
			state: 2,
			dialog: [{ name: "АДМИНИСТРАТОР", message: "Всё окей, всё починим", date: 1695001022473 }],
		},
		{
			id: 1696171022473,
			date: 1696171022473,
			account: "acc",
			name: "Котов Данил Алексеевич",
			place: "1000 ИНСТРУМЕНТОВ",
			phone: "+7 999 999 99 99",
			photo: "",
			question: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.",
			state: 0,
			dialog: [],
		},
		{
			id: 1696251022473,
			date: 1696251022473,
			account: "acc",
			name: "Котов Данил Алексеевич",
			place: "1000 ИНСТРУМЕНТОВ",
			phone: "+7 999 999 99 99",
			photo: "https://trava55.ru/pub/products900/1888.jpg?1618078007",
			question: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.",
			state: 1,
			dialog: [
				{ name: "АДМИНИСТРАТОР", message: "Всё окей, всё починим", date: 1695001022473 },
				{ name: "ПОЛЬЗОВАТЕЛЬ", message: "А как?", date: 1695001022473 },
			],
		},
		{
			id: 1696351022473,
			date: 1696351022473,
			account: "acc",
			name: "Котов Данил Алексеевич",
			place: "1000 ИНСТРУМЕНТОВ",
			phone: "+7 999 999 99 99",
			photo: "",
			question: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.",
			state: 0,
			dialog: [],
		},
	],
}

const ADD_ANSWER = "ADD_ANSWER"
const SET_STATE = "SET_STATE"
const ADD_MANY_QUESTIONS = "ADD_MANY_QUESTIONS"
const EDIT_QUESTION = "EDIT_QUESTION"

export const questionsReducer: Reducer = (state = defaultState, action) => {
	switch (action.type) {
		case ADD_MANY_QUESTIONS:
			return { ...state, data: [...state.data, ...action.payload] }
		case ADD_ANSWER:
			console.log("123")
			return state
		case SET_STATE:
			return { ...state, data: [...state.data, action.payload] }
		case EDIT_QUESTION:
			return { ...state, data: [...state.data, action.payload] }
		default:
			return state
	}
}

export const AddManyQuestionsAction = (payload: IQuestion[]) => ({ type: ADD_MANY_QUESTIONS, payload })
export const AddAnswerQuestionAction = (payload: IAddAnswer) => ({ type: ADD_ANSWER, payload })
export const SetStateQuestionAction = (payload: string) => ({ type: SET_STATE, payload })
