import React from "react"
import styles from "./places.module.css"
import { Button, Container } from "@mui/material"
import { PlacesTable } from "./PlacesTable"
import { AddPlace } from "./AddPlace"

export function Places() {
	const [isModalOpen, setIsModalOpen] = React.useState(false)

	const handleClick = () => {
		setIsModalOpen(true)
	}
	return (
		<Container className={styles.wrapper}>
			<Button variant="contained" color="secondary" onClick={handleClick}>
				Добавить точку
			</Button>
			{isModalOpen && (
				<AddPlace
					onClick={() => {
						setIsModalOpen(true)
					}}
					onClose={() => {
						setIsModalOpen(false)
					}}
					open={isModalOpen}
				/>
			)}
			<PlacesTable />
		</Container>
	)
}
