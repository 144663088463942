import React from "react"
import styles from "./access.module.css"
import { Button, Container, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { AccessTable } from "./AccessTable"
import { SubmitHandler, useForm } from "react-hook-form"

export function Access() {
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IFormInput>({ defaultValues: { time: 1990 } })

	interface IFormInput {
		time: number
	}

	const onSubmit: SubmitHandler<IFormInput> = () => {
		console.log(getValues("time"))
	}

	return (
		<Container className={styles.wrapper}>
			<Typography variant="h4" component="h1">
				Доступ к сервису активен до XX.XX.XXXX.
			</Typography>
			<form className={styles.prolongWrapper} onSubmit={handleSubmit(onSubmit)}>
				<Typography variant="body1" component="h2">
					Продлить доступ на
				</Typography>
				<FormControl size="small" className={styles.labelWrapper}>
					<InputLabel id="demo-simple-select-label">Срок</InputLabel>
					<Select labelId="demo-simple-select-label" id="demo-simple-select" label="Срок" {...register("time", { required: "Это поле обязательно для заполнения" })}>
						<MenuItem value={19990} key={1}>
							12 месяцев (19990 руб.)
						</MenuItem>
						<MenuItem value={1990} key={2}>
							30 дней (1990 руб.)
						</MenuItem>
					</Select>
				</FormControl>
				<p className={styles.error}>{errors.time?.message}</p>
				<Button color="secondary" variant="contained" type="submit">
					Продлить доступ
				</Button>
			</form>
			<AccessTable />
		</Container>
	)
}
