import * as React from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import { visuallyHidden } from "@mui/utils"
import styles from "./accessTable.module.css"
import { IAccess } from "../../store/accessReducer"
import { useSelector } from "react-redux"
import dayjs, { Dayjs } from "dayjs"
import { DatePicker } from "@mui/x-date-pickers"
import { Typography } from "@mui/material"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

interface HeadCell {
	disablePadding: boolean
	id: keyof IAccess
	label: string
	numeric: boolean
	width?: number
}

const headCells: readonly HeadCell[] = [
	{
		id: "date",
		numeric: true,
		disablePadding: true,
		label: "Дата оплаты",
		width: 80,
	},
	{
		id: "money",
		numeric: true,
		disablePadding: false,
		label: "Сумма",
		width: 80,
	},
	{
		id: "before",
		numeric: true,
		disablePadding: true,
		label: "Доступ активен до",
		width: 80,
	},
	{
		id: "receipt",
		numeric: false,
		disablePadding: false,
		label: "Чек",
		width: 130,
	},
	{
		id: "auto",
		numeric: true,
		disablePadding: false,
		label: "Автоплатёж",
		width: 100,
	},
]

interface EnhancedTableProps {
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IAccess) => void
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
	order: Order
	orderBy: string
	rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props
	const createSortHandler = (property: keyof IAccess) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow className={styles.head}>
				{headCells.map(headCell => (
					<TableCell width={headCell.width} key={headCell.id} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export function AccessTable() {
	const [order, setOrder] = React.useState<Order>("desc")
	const [orderBy, setOrderBy] = React.useState<keyof IAccess>("id")
	const [selected, setSelected] = React.useState<readonly string[]>([])
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [datePicker, setDatePicker] = React.useState<Dayjs | null>(dayjs("1997-12-12"))
	const [datePicker2, setDatePicker2] = React.useState<Dayjs | null>(dayjs(new Date()))
	let convertedDate = {
		from: dayjs("1998-12-12").unix(),
		to: dayjs(new Date()).unix(),
	}

	React.useEffect(() => {
		if (datePicker && datePicker2) {
			convertedDate = {
				from: datePicker.unix(),
				to: datePicker2.unix(),
			}
		}
	}, [datePicker, datePicker2])

	let rows: IAccess[] = useSelector((state: { access: { data: IAccess[] } }) => state.access.data)

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IAccess) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map(n => n.receipt)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	function addZero(num: number) {
		var str = num.toString()
		return str.length === 1 ? "0" + str : str
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

	// eslint-disable-next-line
	const visibleRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage, rows])

	return (
		<Box sx={{ width: "100%", mt: "20px" }}>
			<Paper sx={{ width: "100%", mb: 2 }} className={styles.formWrapper}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}>
						<EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
						<TableBody>
							{visibleRows.length > 0 ? (
								visibleRows.map((row, index) => {
									const isItemSelected = isSelected(row.receipt)
									const time = new Date(row.date)
									const date = addZero(time.getDate()) + "." + addZero(time.getMonth()) + "." + addZero(time.getFullYear()) + " " + addZero(time.getHours()) + ":" + addZero(time.getMinutes())

									const time2 = new Date(row.before)
									const date2 = addZero(time2.getDate()) + "." + addZero(time2.getMonth()) + "." + addZero(time2.getFullYear()) + " " + addZero(time2.getHours()) + ":" + addZero(time2.getMinutes())

									return (
										<TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected} sx={{ cursor: "pointer" }}>
											<TableCell>{date}</TableCell>
											<TableCell>{row.money} руб 00 коп</TableCell>
											<TableCell>{date2}</TableCell>
											<TableCell>
												<a href={row.receipt} target="_blank" rel="noreferrer" className={styles.receipt}>
													{row.receipt}
												</a>
											</TableCell>
											<TableCell>{row.auto}</TableCell>
										</TableRow>
									)
								})
							) : (
								<TableCell colSpan={6}>
									<p className={styles.noPlaces}>Оплаты отсутствуют</p>
								</TableCell>
							)}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination showFirstButton={true} showLastButton={true} labelRowsPerPage="КОЛ-ВО ЗАПИСЕЙ НА СТРАНИЦЕ" rowsPerPageOptions={[10, 30, 50, 100, rows.length]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
				<Typography variant="body1" className={styles.statusFilter}>
					СОРТИРОВКА ПО ДАТЕ
					<DatePicker label="ОТ" format="DD/MM/YYYY" value={datePicker} onChange={newValue => setDatePicker(newValue)} className={styles.datePicker} />
					<DatePicker label="ДО" format="DD/MM/YYYY" value={datePicker2} onChange={newValue => setDatePicker2(newValue)} className={styles.datePicker} />
				</Typography>
			</Paper>
		</Box>
	)
}
