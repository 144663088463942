import React, { useState } from "react"
import styles from "./addplace.module.css"
import { SubmitHandler, useForm } from "react-hook-form"
import { Alert, Box, Button, Modal, Snackbar, TextField, Typography } from "@mui/material"
import ReactDOM from "react-dom"
import { useDispatch } from "react-redux"
import { AddPlaceAction } from "../../store/placesReducer"

interface IInputs {
	name: string
	address: string
	phone: string
}

interface IAddPlace {
	open: boolean
	onClick: () => void
	onClose: () => void
}

export function AddPlace({ open, onClick, onClose }: IAddPlace) {
	const [IsOpenSnackbar, setOpenSnackbar] = useState(false)
	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IInputs>()

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return
		}
		onClose()
	}
	const onSubmit: SubmitHandler<IInputs> = () => {
		setOpenSnackbar(true)
		onClose()
		const data = {
			id: Date.now(),
			name: getValues("name"),
			place: getValues("address"),
			phone: getValues("phone"),
			rating: 5,
		}
		dispatch(AddPlaceAction(data))
	}

	const node = document.querySelector(`#modal_root`)
	if (!node) return null

	return ReactDOM.createPortal(
		<Modal open={open} onClose={onClose}>
			<Box className={styles.formWrapper}>
				<Typography variant="h4" component="h1" sx={{ mb: "20px" }}>
					Добавить точку
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<TextField size="small" id="name" label="Название точки" variant="outlined" {...register("name", { required: "Это поле обязательно для заполнения", minLength: 3 })} className={styles.input} />
					<p className={styles.error}>{errors.name?.message}</p>
					<TextField size="small" autoComplete="street-address" id="address" label="Адрес" variant="outlined" {...register("address", { required: "Это поле обязательно для заполнения" })} className={styles.input} />
					<p className={styles.error}>{errors.address?.message}</p>
					<TextField size="small" inputMode="tel" autoComplete="tel" id="phone" label="Телефон" variant="outlined" {...register("phone", { required: "Это поле обязательно для заполнения" })} className={styles.input} />
					<p className={styles.error}>{errors.phone?.message}</p>

					<Button color="secondary" variant="contained" type="submit">
						Добавить
					</Button>
				</form>
				<Button color="secondary" onClick={onClose} sx={{ mt: "10px" }}>
					Закрыть
				</Button>
				<Snackbar open={IsOpenSnackbar} autoHideDuration={3000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="success">
						Точка успешно добавлена.
					</Alert>
				</Snackbar>
			</Box>
		</Modal>,
		node
	)
}
