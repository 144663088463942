import React from "react"
import styles from "./nomatch.module.css"
import { Button, Container, Typography } from "@mui/material"
import { Link } from "react-router-dom"

export function NoMatch() {
	return (
		<Container className={styles.wrapper}>
			<Typography variant="h3" component="h1" sx={{ textAlign: "center" }}>
				Ошибка 404
			</Typography>
			<Typography variant="body1" sx={{ textAlign: "center" }}>
				Страницы, которую Вы пытались открыть не существует
			</Typography>
			<Link to="/">
				<Button variant="contained" color="secondary" size="large">
					Вернуться на главную
				</Button>
			</Link>
		</Container>
	)
}
