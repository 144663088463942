import { Reducer } from "redux"

export interface IReview {
	id: number
	date: number
	account: string
	name: string
	place: string
	phone: string
	photo: string
	review: string
	rating: number
	state: number
}

export type reviewState = {
	data: IReview[]
}

const defaultState: reviewState = {
	data: [
		{ id: 1695011022471, date: 1695011022473, account: "accout", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 5, state: 3 },
		{ id: 1695021022472, date: 1695021022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695031022473, date: 1695031022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 4, state: 1 },
		{ id: 1695041022474, date: 1695041022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695051022475, date: 1695051022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 2, state: 2 },
		{ id: 1695061022476, date: 1695061022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 1, state: 1 },
		{ id: 1695011022477, date: 1695011022473, account: "accout", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 5, state: 3 },
		{ id: 1695021022478, date: 1695021022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695031022479, date: 1695031022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 4, state: 1 },
		{ id: 16950410224710, date: 1695041022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 16950510224731, date: 1695051022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 2, state: 2 },
		{ id: 16950610224732, date: 1695061022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 1, state: 1 },
		{ id: 16950110224733, date: 1695011022473, account: "accout", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 5, state: 3 },
		{ id: 16950210224734, date: 1695021022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 16950310224735, date: 1695031022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 4, state: 1 },
		{ id: 16950410224736, date: 1695041022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 16950510224737, date: 1695051022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 2, state: 2 },
		{ id: 16950610224738, date: 1695061022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 1, state: 1 },
		{ id: 16950110224739, date: 1695011022473, account: "accout", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 5, state: 3 },
		{ id: 16950210224730, date: 1695021022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695031022173, date: 1695031022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 4, state: 1 },
		{ id: 1695041022273, date: 1695041022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695051022373, date: 1695051022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 2, state: 2 },
		{ id: 1695061022473, date: 1695061022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 1, state: 1 },
		{ id: 1695011022573, date: 1695011022473, account: "accout", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 5, state: 3 },
		{ id: 1695021022673, date: 1695021022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695031022773, date: 1695031022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 4, state: 1 },
		{ id: 1695041022873, date: 1695041022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695051022973, date: 1695051022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 2, state: 2 },
		{ id: 1695061022073, date: 1695061022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 1, state: 1 },
		{ id: 1695011021473, date: 1695011022473, account: "accout", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 5, state: 3 },
		{ id: 1695021022473, date: 1695021022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695031023473, date: 1695031022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 4, state: 1 },
		{ id: 1695041024473, date: 1695041022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "МЕГА МАГ", photo: "", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 3, state: 0 },
		{ id: 1695051025473, date: 1695051022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 2, state: 2 },
		{ id: 1695061026473, date: 1695061022473, account: "acco", name: "Котов Данил Алексеевич", phone: "+7 999 999 99 99", place: "1000 ИНСТРУМЕНТОВ", photo: "https://catalog.detmir.st/media/bgBw2e2Kt21j3ky1YYplLp1jH1-Fw-Qi1XMbdDvX_Dg=?preset=site_product_gallery_r1500", review: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты.", rating: 1, state: 1 },
	],
}

const ADD_REVIEW = "ADD_REVIEW"
const ADD_MANY_REVIEWS = "ADD_MANY_REVIEWS"

export const reviewsReducer: Reducer = (state: reviewState = defaultState, action) => {
	switch (action.type) {
		case ADD_REVIEW:
			return { ...state, data: [...state.data, action.payload] }
		case ADD_MANY_REVIEWS:
			return { ...state, data: [...state.data, ...action.payload] }
		default:
			return state
	}
}

export const AddHelpAction = (payload: IReview) => ({ type: ADD_REVIEW, payload })
export const AddManyHelpAction = (payload: IReview[]) => ({ type: ADD_MANY_REVIEWS, payload })
