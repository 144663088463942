import React from "react"
import styles from "./reviews.module.css"
import { ReviewTable } from "./ReviewTable"

export function Reviews() {
	return (
		<div className={styles.wrapper}>
			<ReviewTable />
		</div>
	)
}
