import * as React from "react"
import styles from "./header.module.css"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import { Link, NavLink } from "react-router-dom"

const pages = [
	{ name: "Отзывы", link: "/reviews" },
	{ name: "Точки продаж", link: "/places" },
	{ name: "Вопросы", link: "/questions" },
	{ name: "Рассылка", link: "/mailing" },
	{ name: "QR Код", link: "/qrcode" },
	{ name: "Доступ", link: "/access" },
	{ name: "Сменить пароль", link: "/change-pass" },
	{ name: "Служба поддержки", link: "/help" },
]

export function Header() {
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget)
	}
	const handleCloseNavMenu = () => {
		setAnchorElNav(null)
	}

	return (
		<AppBar position="static" className={styles.header}>
			<Toolbar disableGutters>
				<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
					<IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
						<MenuIcon />
					</IconButton>
					<Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} keepMounted transformOrigin={{ vertical: "top", horizontal: "left" }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} sx={{ display: { xs: "block", md: "none" } }}>
						{pages.map(page => (
							<MenuItem key={page.name} onClick={handleCloseNavMenu}>
								<Link to={page.link} className={styles.headerLink}>
									<Button onClick={handleCloseNavMenu}>{page.name}</Button>
								</Link>
							</MenuItem>
						))}
					</Menu>
				</Box>

				<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "flex-start", gap: "20px", width: "100%", paddingRight: "10px" }} id={styles.linkWrapper}>
					{pages.map(page => (
						<NavLink to={page.link} key={page.name} onClick={handleCloseNavMenu} className={({ isActive, isPending }) => (isPending ? "pending" : isActive ? styles.active : "")}>
							<Button onClick={handleCloseNavMenu} sx={{ my: 2, color: "white", display: "block" }}>
								{page.name}
							</Button>
						</NavLink>
					))}
				</Box>
			</Toolbar>
		</AppBar>
	)
}
export default Header
