import * as React from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import { visuallyHidden } from "@mui/utils"
import styles from "./places.module.css"
import { IconButton, Rating } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { EditPlace } from "../EditPlace"
import { useDispatch, useSelector } from "react-redux"
import { DelPlaceAction, IPlace } from "../../store/placesReducer"
import { Confirmation } from "../../Confirmation"
import { MyTooltip } from "../../MyTooltip"

interface Data {
	id: number
	name: string
	place: string
	phone: string
	rating: number
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

interface HeadCell {
	disablePadding: boolean
	id: keyof Data
	label: string
	width?: number
}

const headCells: readonly HeadCell[] = [
	{
		id: "name",
		disablePadding: true,
		label: "Название точки",
		width: 200,
	},
	{
		id: "place",
		disablePadding: false,
		label: "Месторасположение",
		width: 350,
	},
	{
		id: "phone",
		disablePadding: false,
		label: "Номер телефона",
		width: 160,
	},
	{
		id: "rating",
		disablePadding: false,
		label: "Рейтинг",
		width: 170,
	},
]

interface EnhancedTableProps {
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
	order: Order
	orderBy: string
	rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props
	const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow className={styles.head}>
				{headCells.map(headCell => (
					<TableCell width={headCell.width} key={headCell.id} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell>Действия</TableCell>
			</TableRow>
		</TableHead>
	)
}

export function PlacesTable() {
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<keyof Data>("id")
	const [selected, setSelected] = React.useState<readonly string[]>([])
	const [page, setPage] = React.useState(0)
	const [editID, setEditID] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [isConfirm, setIsConfirm] = React.useState(false)
	const [dataConfirm, setDataConfirm] = React.useState({ text: "", function: () => {} })
	const dispatch = useDispatch()
	let rows: IPlace[] = useSelector((state: { places: { data: IPlace[] } }) => state.places.data)

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map((n: IPlace) => n.name)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	function deletePlace(postID: number) {
		dispatch(DelPlaceAction(postID))
	}

	function editPlace(postID: number) {
		setEditID(postID)
		setIsEditModalOpen(true)
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
	let visibleRows: IPlace[] = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage, rows])

	const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)

	return (
		<Box sx={{ margin: "0 auto", mt: "20px" }}>
			<Paper sx={{ margin: "0 auto", mb: 2 }} className={styles.formWrapper}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}>
						<EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
						<TableBody>
							{visibleRows.length > 0 ? (
								visibleRows.map((row: IPlace) => {
									const isItemSelected = isSelected(row.name)
									return (
										<TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected} sx={{ cursor: "pointer" }}>
											<TableCell>{row.name}</TableCell>
											<TableCell>{row.place}</TableCell>
											<TableCell>{row.phone}</TableCell>
											<TableCell>
												<MyTooltip title="5054 человека оценили">
													<div className={styles.ratingWrapper}>
														<p className={styles.rating}>{row.rating}</p>
														<Rating name="read-only" precision={0.1} value={row.rating} readOnly />
													</div>
												</MyTooltip>
											</TableCell>
											<TableCell>
												<div className={styles.buttonWrapper}>
													<MyTooltip title="РЕДАКТИРОВАТЬ ТОЧКУ">
														<IconButton
															onClick={() => {
																editPlace(row.id)
															}}
														>
															<EditIcon />
														</IconButton>
													</MyTooltip>
													<MyTooltip title="УДАЛИТЬ ТОЧКУ">
														<IconButton
															onClick={() => {
																setIsConfirm(true)
																setDataConfirm({
																	text: "Вы собираетесь удалить точку продажи. Отменить данное действие будет невозможно",
																	function: () => {
																		deletePlace(row.id)
																	},
																})
															}}
														>
															<DeleteIcon />
														</IconButton>
													</MyTooltip>
												</div>
											</TableCell>
										</TableRow>
									)
								})
							) : (
								<TableCell colSpan={6}>
									<p className={styles.noPlaces}>Точки отсутствуют</p>
								</TableCell>
							)}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination showFirstButton={true} showLastButton={true} labelRowsPerPage="КОЛ-ВО ЗАПИСЕЙ НА СТРАНИЦЕ" rowsPerPageOptions={[10, 30, 50, 100, rows.length]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
			</Paper>
			{isConfirm && (
				<Confirmation
					open={isConfirm}
					handleClose={() => {
						setIsConfirm(false)
					}}
					handleClick={dataConfirm.function}
					text={dataConfirm.text}
				/>
			)}
			{isEditModalOpen && (
				<EditPlace
					placeID={editID}
					onClose={() => {
						setIsEditModalOpen(false)
					}}
					open={isEditModalOpen}
				/>
			)}
		</Box>
	)
}
