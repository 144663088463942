import React from "react"
import styles from "./editrequest.module.css"
import { SubmitHandler, useForm } from "react-hook-form"
import { Alert, Box, Button, Modal, Snackbar, TextField, Typography } from "@mui/material"
import ReactDOM from "react-dom"

interface IInputs {
	question: string
	file: string
}

interface IEditRequest {
	open: boolean
	onClose: () => void
}

export function EditRequest({ open, onClose }: IEditRequest) {
	const [IsOpenSnackbar, setOpenSnackbar] = React.useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IInputs>()

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return
		}
		onClose()
	}

	function handleCloseRequest() {
		console.log("запрос закрывается")
		onClose()
	}

	const onSubmit: SubmitHandler<IInputs> = () => {
		setOpenSnackbar(true)
		onClose()
	}

	const node = document.querySelector(`#modal_root`)
	if (!node) return null

	return ReactDOM.createPortal(
		<Modal open={open} onClose={onClose}>
			<Box className={styles.formWrapper}>
				<Typography variant="h4" component="h1" sx={{ mb: "20px" }}>
					Изменить запрос
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<p>Если у Вас ещё остались вопросы, Вы можете их задать ниже</p>
					<TextField size="small" autoComplete="street-address" id="address" label="Вопрос" variant="outlined" {...register("question", { required: "Это поле обязательно для заполнения" })} className={styles.input} />
					<p className={styles.error}>{errors.question?.message}</p>
					<input type="file" id="file" {...register("file")} />
					<p className={styles.error}>{errors.file?.message}</p>

					<Button color="secondary" variant="contained" type="submit">
						Изменить
					</Button>
					<br />
					<br />
					<Button color="secondary" variant="contained" onClick={handleCloseRequest}>
						Закрыть запрос
					</Button>
				</form>
				<Button color="secondary" onClick={onClose} sx={{ mt: "10px" }}>
					Закрыть
				</Button>
				<Snackbar open={IsOpenSnackbar} autoHideDuration={3000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="success">
						Запрос успешно изменена.
					</Alert>
				</Snackbar>
			</Box>
		</Modal>,
		node
	)
}
