import React from "react"
import ReactDOM from "react-dom"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

export interface IConfirmationProps {
	open: boolean
	handleClose: () => void
	handleClick: () => void
	text: string
}

export function Confirmation({ open, handleClose, handleClick, text }: IConfirmationProps) {
	const node = document.querySelector(`#modal_root`)
	if (!node) return null

	return ReactDOM.createPortal(
		<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">Вы уверены?</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Отмена</Button>
				<Button
					onClick={() => {
						handleClick()
						handleClose()
					}}
					autoFocus
				>
					Продолжить
				</Button>
			</DialogActions>
		</Dialog>,
		node
	)
}
