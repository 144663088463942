import { Reducer } from "redux"

export interface IMailing {
	id: number
	title: string
	content: string
	photo: string
	state: number
	url?: string
}

export type mailingState = {
	ban: boolean
	data: IMailing[]
}

const defaultState: mailingState = {
	ban: false,
	data: [
		{ id: 1696057073598, title: "Распродажа", content: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Родного переулка рот lorem лучше буквоград, подпоясал над, бросил снова ее рекламных, ведущими путь запятой мир гор живет подзаголовок города.", photo: "https://paraller-ok.ru/wa-data/public/shop/products/45/13/1345/images/2889/2889.750@2x.jpg", state: 1 },
		{ id: 1696127176598, title: "Распродажа", content: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Родного переулка рот lorem лучше буквоград, подпоясал над, бросил снова ее рекламных, ведущими путь запятой мир гор живет подзаголовок города.", photo: "", state: 1 },
		{ id: 1696157173598, title: "Распродажа", content: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Родного переулка рот lorem лучше буквоград, подпоясал над, бросил снова ее рекламных, ведущими путь запятой мир гор живет подзаголовок города.", photo: "", state: 0 },
		{ id: 1696153173438, title: "Распродажа", content: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Родного переулка рот lorem лучше буквоград, подпоясал над, бросил снова ее рекламных, ведущими путь запятой мир гор живет подзаголовок города.", photo: "", state: 0 },
		{ id: 1696155173598, title: "Распродажа", content: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Родного переулка рот lorem лучше буквоград, подпоясал над, бросил снова ее рекламных, ведущими путь запятой мир гор живет подзаголовок города.", photo: "https://paraller-ok.ru/wa-data/public/shop/products/45/13/1345/images/2889/2889.750@2x.jpg", state: 0 },
		{ id: 1696159178298, title: "Распродажа", content: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Родного переулка рот lorem лучше буквоград, подпоясал над, бросил снова ее рекламных, ведущими путь запятой мир гор живет подзаголовок города.", photo: "https://paraller-ok.ru/wa-data/public/shop/products/45/13/1345/images/2889/2889.750@2x.jpg", state: 1 },
	],
}

const ADD_MAIL = "ADD_MAIL"
const SEND_MAIL = "SEND_MAIL"
const ADD_MANY_MAILS = "ADD_MANY_MAILS"
const EDIT_MAIL = "EDIT_MAIL"
const DEL_MAIL = "DEL_MAIL"

export const mailingReducer: Reducer<mailingState> = (state: mailingState = defaultState, action: any) => {
	switch (action.type) {
		case SEND_MAIL:
			return { ...state, ban: true }
		case ADD_MANY_MAILS:
			return { ...state, data: [...state.data, ...action.payload] }
		case ADD_MAIL:
			return { ...state, data: [...state.data, action.payload] }
		case EDIT_MAIL:
			console.log({ ...state.data.find((el: IMailing) => el.id === action.payload.id), ...action.payload })
			return { ...state, data: [...state.data, { ...state.data.find((el: IMailing) => el.id === action.payload.id), ...action.payload }] }
		case DEL_MAIL:
			return { ...state, data: state.data.filter((data: IMailing) => data.id !== action.payload) }
		default:
			return state
	}
}

export const SendMailAction = () => ({ type: SEND_MAIL })
export const AddMailAction = (payload: IMailing) => ({ type: ADD_MAIL, payload })
export const AddManyMailsAction = (payload: IMailing[]) => ({ type: ADD_MANY_MAILS, payload })
export const EditMailAction = (payload: IMailing) => ({ type: EDIT_MAIL, payload })
export const DelMailAction = (payload: number) => ({ type: DEL_MAIL, payload })
