import React from "react"
import styles from "./help.module.css"
import { Button, Container } from "@mui/material"
import { AddRequest } from "./AddRequest"
import { HelpTable } from "./HelpTable/HelpTable"

export function Help() {
	const [isModalOpen, setIsModalOpen] = React.useState(false)

	const handleClick = () => {
		setIsModalOpen(true)
	}
	return (
		<Container className={styles.wrapper}>
			<Button variant="contained" color="secondary" onClick={handleClick}>
				Обратиться в поддержку
			</Button>
			{isModalOpen && (
				<AddRequest
					onClose={() => {
						setIsModalOpen(false)
					}}
					open={isModalOpen}
				/>
			)}
			<HelpTable />
		</Container>
	)
}
