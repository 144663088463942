import React from "react"
import styles from "./qrcode.module.css"
import { Information } from "./Information"
import { Code } from "./Code"
import { Container } from "@mui/material"

export function QrCode() {
	return (
		<Container className={styles.wrapper}>
			<Code />
			<Information />
		</Container>
	)
}
