import React from "react"
import styles from "./changepassword.module.css"
import { Alert, Button, Container, Snackbar, TextField, Typography } from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

interface IInputs {
	email: string
	password: string
	confirmPassword: string
}

export function ChangePassword() {
	const [open, setOpen] = React.useState(false)

	const schema = yup
		.object({
			email: yup
				.string()
				.matches(/[-A-Za-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-A-Za-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?/, "Формат адреса почты: ********@****.**")
				.required("Почта является обязательной"),
			password: yup.string().min(8, "Минимальная длина пароля 8 символов").required("Пароль является обязательным"),
			confirmPassword: yup
				.string()
				.oneOf([yup.ref("password"), ""], "Пароли не совпадают")
				.min(8, "Минимальная длина пароля 8 символов")
				.required("Пароль является обязательным"),
		})
		.required()

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IInputs>({ resolver: yupResolver(schema) })

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return
		}
		setOpen(false)
	}

	const onSubmit: SubmitHandler<IInputs> = () => {
		setOpen(true)
		reset({ email: "", password: "", confirmPassword: "" })
	}

	return (
		<Container className={styles.formWrapper}>
			<Typography variant="h4" component="h1" sx={{ mb: "20px", textTransform: "uppercase" }}>
				Изменить пароль
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)} id="change-pass-form">
				<TextField type="email" size="small" autoComplete="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" label="Введите e-mail" variant="outlined" {...register("email")} className={styles.input} />
				<p className={styles.error}>{errors.email?.message}</p>
				<TextField type="password" size="small" autoComplete="new-password" id="password" label="Введите пароль" variant="outlined" {...register("password")} className={styles.input} />
				<p className={styles.error}>{errors.password?.message}</p>
				<TextField type="password" size="small" autoComplete="new-password" id="confirmPassword" label="Повторите пароль" variant="outlined" {...register("confirmPassword")} className={styles.input} />
				<p className={styles.error}>{errors.confirmPassword?.message}</p>

				<Button color="secondary" variant="contained" type="submit">
					Изменить
				</Button>
			</form>
			<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success">
					Пароль успешно изменён
				</Alert>
			</Snackbar>
		</Container>
	)
}
