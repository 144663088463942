import React, { useState } from "react"
import styles from "./mailing.module.css"
import { Button, Container } from "@mui/material"
import { MailingTable } from "./MailingTable"
import { AddMailing } from "./AddMailing"

export function Mailing() {
	const [isModalOpen, setIsModalOpen] = useState(false)

	return (
		<Container className={styles.wrapper}>
			<Button
				color="secondary"
				variant="contained"
				onClick={() => {
					setIsModalOpen(true)
				}}
			>
				Добавить рассылку
			</Button>
			<MailingTable />
			{isModalOpen && (
				<AddMailing
					onClose={() => {
						setIsModalOpen(false)
					}}
					open={isModalOpen}
				/>
			)}
		</Container>
	)
}
