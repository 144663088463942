import * as React from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import { visuallyHidden } from "@mui/utils"
import styles from "./review.module.css"
import { Button, FormControl, IconButton, InputLabel, MenuItem, Rating, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import DoDisturbIcon from "@mui/icons-material/DoDisturb"
import { IPlace } from "../../store/placesReducer"
import { useSelector } from "react-redux"
import { Fancybox } from "../../Fancybox"
import { IReview } from "../../store/reviewsReducer"
import { Confirmation } from "../../Confirmation"
import { MyTooltip } from "../../MyTooltip"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs, { Dayjs } from "dayjs"
import { useForm } from "react-hook-form"
import { ReviewModal } from "../ReviewModal"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

interface HeadCell {
	disablePadding: boolean
	id: keyof IReview
	label: string
	numeric: boolean
	width?: number
}

const headCells: readonly HeadCell[] = [
	{
		id: "date",
		numeric: false,
		disablePadding: true,
		label: "Дата отзыва",
		width: 100,
	},
	{
		id: "account",
		numeric: false,
		disablePadding: false,
		label: "Telegram",
		width: 60,
	},
	{
		id: "name",
		numeric: false,
		disablePadding: true,
		label: "ФИО клиента",
		width: 180,
	},
	{
		id: "phone",
		numeric: true,
		disablePadding: false,
		label: "Номер телефона",
		width: 120,
	},
	{
		id: "place",
		numeric: false,
		disablePadding: false,
		label: "Точка продажи",
		width: 130,
	},
	{
		id: "photo",
		numeric: false,
		disablePadding: false,
		label: "Фото",
		width: 60,
	},
	{
		id: "review",
		numeric: false,
		disablePadding: false,
		label: "Отзыв пользователя",
		width: 260,
	},
	{
		id: "rating",
		numeric: true,
		disablePadding: false,
		label: "Оценка",
		width: 100,
	},
	{
		id: "state",
		numeric: false,
		disablePadding: false,
		label: "Статус",
		width: 250,
	},
]

interface EnhancedTableProps {
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IReview) => void
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
	order: Order
	orderBy: string
	rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props

	const createSortHandler = (property: keyof IReview) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow className={styles.head}>
				{headCells.map(headCell => (
					<TableCell width={headCell.width} key={headCell.id} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false} className={styles.header}>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "По уменьшению" : "По увелечнию"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export function ReviewTable() {
	const [order, setOrder] = React.useState<Order>("desc")
	const [orderBy, setOrderBy] = React.useState<keyof IReview>("id")
	const [selected, setSelected] = React.useState<readonly string[]>([])
	const [page, setPage] = React.useState(0)
	const [pages, setPages] = React.useState(1)
	const [filterText, setFilterText] = React.useState("")
	const [dataPlace, setDataPlace] = React.useState(<></>)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [isModalOpen, setIsModalOpen] = React.useState(false)
	const [reviewID, setReviewID] = React.useState(0)
	const [isConfirm, setIsConfirm] = React.useState(false)
	const [dataConfirm, setDataConfirm] = React.useState({ text: "", function: () => {} })
	const [statusFilter, setStatusFilter] = React.useState("4")
	const [datePicker, setDatePicker] = React.useState<Dayjs | null>(dayjs("1997-12-12"))
	const [datePicker2, setDatePicker2] = React.useState<Dayjs | null>(dayjs(new Date()))

	let convertedDate = {
		from: dayjs("1998-12-12").unix(),
		to: dayjs(new Date()).unix(),
	}

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm({ defaultValues: { search: "" } })

	const onSearch = () => {
		setFilterText(getValues("search"))
	}

	React.useEffect(() => {
		if (datePicker && datePicker2) {
			convertedDate = {
				from: datePicker.unix(),
				to: datePicker2.unix(),
			}
		}
	}, [datePicker, datePicker2])

	let places: IPlace[] = useSelector((state: { places: { data: IPlace[] } }) => state.places.data)
	let rows: IReview[] = useSelector((state: { reviews: { data: IReview[] } }) =>
		state.reviews.data
			.filter(el => (Number(statusFilter) === 4 ? el === el : el.state === Number(statusFilter)))
			.filter(el => dayjs(el.date).unix() > convertedDate.from)
			.filter(el => el.review.includes(filterText) || el.name.includes(filterText) || el.account.includes(filterText) || el.phone.includes(filterText) || el.place.includes(filterText))
	)

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IReview) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleChange = (event: SelectChangeEvent) => {
		setStatusFilter(event.target.value as string)
	}

	const handleSelectPage = (event: SelectChangeEvent) => {
		getPages()
		const pageNumber = Number(event.target.value)
		setPage(pageNumber)
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map(n => n.name)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	function getRowColor(state: number) {
		let name = ""
		switch (state) {
			case 1:
				name = styles.success
				break
			case 2:
				name = styles.success
				break
			case 0:
				name = styles.new
				break
			case 3:
				name = styles.spam
				break
		}
		return name
	}

	function getPages() {
		const pages = Math.ceil(rows.length / rowsPerPage)
		setPages(pages)
	}

	function limitStr(str: string, n: number, symb?: string) {
		if (!n && !symb) return str
		if (str.length < n) return str
		symb = symb || "..."
		return str.substr(0, n - symb.length) + symb
	}

	function handleDel(id: number) {
		console.log("delete")
	}

	function handleSuccess(id: number) {
		console.log("success")
	}

	function handleOverPlace(name: string) {
		const target: IPlace[] = places.filter((el: IPlace) => el.name === name)
		target.length > 0
			? setDataPlace(
					<React.Fragment>
						{`АДРЕС: ${target[0].place}.`}
						<br />
						{`ТЕЛЕФОН: ${target[0].phone}`}
					</React.Fragment>
			  )
			: setDataPlace(<React.Fragment>МАГАЗИН НЕ НАЙДЕН</React.Fragment>)
	}

	function addZero(num: number) {
		var str = num.toString()
		return str.length === 1 ? "0" + str : str
	}

	function getState(state: number) {
		let textState = ""
		switch (state) {
			case 0:
				textState = "Новый отзыв"
				break
			case 1:
				textState = "Обработан"
				break
			case 2:
				textState = "Выплата произведена"
				break
			case 3:
				textState = "Отклонён"
				break
		}
		return textState
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
	// eslint-disable-next-line
	const visibleRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage, rows])

	return (
		<Box sx={{ width: "100%", mt: "20px" }}>
			<Paper sx={{ width: "100%", mb: 2 }} className={styles.formWrapper}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}>
						<EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
						<TableBody>
							{visibleRows.length > 0 ? (
								visibleRows.map(row => {
									const isItemSelected = isSelected(row.name)
									const link = "https://t.me/" + row.account
									const time = new Date(row.date)
									const date = addZero(time.getDate()) + "." + addZero(time.getMonth()) + "." + addZero(time.getFullYear()) + " " + addZero(time.getHours()) + ":" + addZero(time.getMinutes())

									return (
										<TableRow
											hover
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											sx={{ cursor: "pointer" }}
											className={getRowColor(row.state)}
											onClick={() => {
												setIsModalOpen(true)
												setReviewID(row.id)
											}}
										>
											<TableCell>{date}</TableCell>
											<TableCell>
												<a href={link} target="_blank" rel="noreferrer">
													@{row.account}
												</a>
											</TableCell>
											<TableCell>{row.name}</TableCell>
											<TableCell>{row.phone}</TableCell>
											<TableCell
												onMouseOver={() => {
													handleOverPlace(row.place)
												}}
											>
												<MyTooltip title={dataPlace}>
													<p>{row.place}</p>
												</MyTooltip>
											</TableCell>
											<TableCell>
												{row.photo ? (
													<Fancybox options={{ Carousel: { infinite: false } }}>
														<a data-fancybox href={row.photo}>
															<img src={row.photo} height="60" alt={row.name} />
														</a>
													</Fancybox>
												) : (
													"Без фото"
												)}
											</TableCell>
											<TableCell>
												<MyTooltip title={row.review}>
													<p>{limitStr(row.review, 70)}</p>
												</MyTooltip>
											</TableCell>
											<TableCell>
												<Rating name="read-only" value={row.rating} readOnly />
											</TableCell>
											<TableCell>
												<div className={styles.buttonWrapper}>
													{getState(row.state)}
													{row.state === 0 && (
														<div className={styles.buttonWrapper}>
															<MyTooltip title="ОТКЛОНИТЬ ЗАЯВКУ">
																<IconButton
																	onClick={() => {
																		setIsConfirm(true)
																		setDataConfirm({
																			text: "Вы собираетесь отклонить отзыв. Отменить данное действие будет невозможно",
																			function: () => {
																				handleDel(row.id)
																			},
																		})
																	}}
																>
																	<DoDisturbIcon />
																</IconButton>
															</MyTooltip>
															<MyTooltip title="ЗАКРЫТЬ ЗАЯВКУ, ВЫПЛАТА ПРОИЗВЕДЕНА">
																<IconButton
																	onClick={() => {
																		setIsConfirm(true)
																		setDataConfirm({
																			text: "Вы собираетесь перевести отзыв в обработанные. Отменить данное действие будет невозможно",
																			function: () => {
																				handleSuccess(row.id)
																			},
																		})
																	}}
																>
																	<DoneIcon />
																</IconButton>
															</MyTooltip>
														</div>
													)}
												</div>
											</TableCell>
										</TableRow>
									)
								})
							) : (
								<TableCell colSpan={10}>
									<p className={styles.noPlaces}>Отзывы отсутствуют</p>
								</TableCell>
							)}
							{isConfirm && (
								<Confirmation
									open={isConfirm}
									handleClose={() => {
										setIsConfirm(false)
									}}
									handleClick={dataConfirm.function}
									text={dataConfirm.text}
								/>
							)}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<div className={styles.paginationWrapper}>
					<FormControl sx={{ width: "150px" }} size="small">
						<InputLabel id="demo-simple-select-label">Страница</InputLabel>
						<Select labelId="demo-simple-select-label" id="demo-simple-select" value={String(page)} label="Страница" onChange={handleSelectPage} onOpen={getPages}>
							{[...Array(pages)].map((el, i) => {
								return <MenuItem value={i}>{i + 1} страница</MenuItem>
							})}
						</Select>
					</FormControl>
					<TablePagination showFirstButton={true} showLastButton={true} labelRowsPerPage="КОЛ-ВО ЗАПИСЕЙ НА СТРАНИЦЕ" rowsPerPageOptions={[10, 30, 50, 100, rows.length]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
				</div>
				<form onSubmit={handleSubmit(onSearch)} className={styles.statusFilter}>
					<div style={{ maxWidth: "37%" }}>
						<TextField type="search" sx={{ width: "300px", maxWidth: "100%" }} size="small" id="outlined-basic" label="Поиск" variant="outlined" {...register("search")} />
					</div>
					<Button variant="contained" color="secondary" type="submit">
						Искать
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => {
							setFilterText("")
							reset()
						}}
					>
						Отмена
					</Button>
				</form>
				<Typography variant="body1" className={styles.statusFilter}>
					СОРТИРОВКА ПО СТАТУСУ
					<FormControl sx={{ width: "150px" }} size="small">
						<InputLabel id="demo-simple-select-label">Статус</InputLabel>
						<Select labelId="demo-simple-select-label" id="demo-simple-select" value={statusFilter} label="Статус" onChange={handleChange}>
							<MenuItem value={4} defaultChecked>
								Все отзывы
							</MenuItem>
							<MenuItem value={0}>Новый отзыв</MenuItem>
							<MenuItem value={1}>Обработан</MenuItem>
							<MenuItem value={2}>Выплата произведена</MenuItem>
							<MenuItem value={3}>Отклонён</MenuItem>
						</Select>
					</FormControl>
				</Typography>
				<Typography variant="body1" className={styles.statusFilter}>
					СОРТИРОВКА ПО ДАТЕ
					<DatePicker label="ОТ" format="DD/MM/YYYY" value={datePicker} onChange={newValue => setDatePicker(newValue)} className={styles.datePicker} />
					<DatePicker label="ДО" format="DD/MM/YYYY" value={datePicker2} onChange={newValue => setDatePicker2(newValue)} className={styles.datePicker} />
				</Typography>
			</Paper>
			{isModalOpen && (
				<ReviewModal
					id={reviewID}
					onClose={() => {
						setIsModalOpen(false)
					}}
					open={isModalOpen}
				/>
			)}
		</Box>
	)
}
