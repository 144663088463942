import { Reducer } from "redux"

export interface IPlace {
	id: number
	name: string
	place: string
	phone: string
	rating: number
}

interface editPlace {
	id: number
	name: string
	place: string
	phone: string
}

export type placeState = {
	data: IPlace[]
}

const defaultState: placeState = {
	data: [
		{ id: 1696066112633, name: "ВСЁ ДЛЯ ДОМА", place: "241000, г.Брянск, ул.Пушкина, д.25", phone: "+7 999 999 99 99", rating: 5 },
		{ id: 1696066115809, name: "УЮТ", place: "241001, г.Брянск, ул.Пушкина, д.65", phone: "+7 999 999 99 43", rating: 2.3 },
		{ id: 1696066119114, name: "МЕГА МАГ", place: "241002, г.Брянск, ул.Пушкина, д.14", phone: "+7 999 999 99 12", rating: 3.5 },
	],
}

const ADD_PLACE = "ADD_PLACE"
const ADD_MANY_PLACES = "ADD_MANY_PLACES"
const EDIT_PLACE = "EDIT_PLACE"
const DEL_PLACE = "DEL_PLACE"

export const placesReducer: Reducer = (state: placeState = defaultState, action) => {
	switch (action.type) {
		case ADD_MANY_PLACES:
			return { ...state, data: [...state.data, ...action.payload] }
		case ADD_PLACE:
			return { ...state, data: [...state.data, action.payload] }
		case EDIT_PLACE:
			return { ...state, data: [...state.data, { ...state.data.find((el: IPlace) => el.id === action.payload.id), ...action.payload }] }
		case DEL_PLACE:
			return { ...state, data: state.data.filter((point: IPlace) => point.id !== action.payload) }
		default:
			return state
	}
}

export const AddPlaceAction = (payload: IPlace) => ({ type: ADD_PLACE, payload })
export const AddManyPlacesAction = (payload: IPlace[]) => ({ type: ADD_MANY_PLACES, payload })
export const EditPlaceAction = (payload: editPlace) => ({ type: EDIT_PLACE, payload })
export const DelPlaceAction = (payload: number) => ({ type: DEL_PLACE, payload })
