import React from "react"
import styles from "./helpmodal.module.css"
import ReactDOM from "react-dom"
import { Alert, Box, Button, Modal, Typography, Snackbar, Divider } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { AddAnswerQuestionAction } from "../../store/questionsReducer"
import { useForm } from "react-hook-form"
import { IHelp } from "../../store/helpReducer"

interface IHelpModal {
	id: number
	open: boolean
	onClose: () => void
}

export function HelpModal({ id, open, onClose }: IHelpModal) {
	const [IsOpenSnackbar, setOpenSnackbar] = React.useState(false)
	let row: IHelp[] = useSelector((state: { help: { data: IHelp[] } }) => state.help.data.filter((point: IHelp) => point.id === id))
	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({ defaultValues: { text: "", file: "" } })

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return
		}
		onClose()
	}

	const onSubmit = () => {
		setOpenSnackbar(true)
		onClose()
		const data = {
			id: id,
			message: getValues("text"),
			file: getValues("file"),
		}
		dispatch(AddAnswerQuestionAction(data))
	}

	function addZero(num: number) {
		var str = num.toString()
		return str.length === 1 ? "0" + str : str
	}

	const time = new Date(row[0].date)
	const date = addZero(time.getDate()) + "." + addZero(time.getMonth()) + "." + addZero(time.getFullYear()) + " " + addZero(time.getHours()) + ":" + addZero(time.getMinutes())

	const node = document.querySelector(`#modal_root`)
	if (!node) return null

	return ReactDOM.createPortal(
		<Modal open={open} onClose={onClose}>
			<Box className={styles.formWrapper}>
				<Typography variant="h6" component="h1" sx={{ mb: "10px" }}>
					{row[0].theme}
				</Typography>
				<div className={styles.dataWrapper}>
					<p className={styles.data}>{row[0].text}</p>
					<p className={styles.data}>{date}</p>
					{row[0].file && <img src={row[0].file} className={styles.questionImg} alt={date} />}
					<ul>
						{row[0].dialog.map(el => {
							const time = new Date(el.date)
							const date = addZero(time.getDate()) + "." + addZero(time.getMonth()) + "." + addZero(time.getFullYear()) + " " + addZero(time.getHours()) + ":" + addZero(time.getMinutes())
							return (
								<li>
									<Divider />
									<p className={styles.sender} style={{ color: el.name === "Администратор" ? "green" : "black" }}>
										{el.name}
									</p>
									<p className={styles.date}>{date}</p>
									<p className={styles.message}>{el.message}</p>
								</li>
							)
						})}
					</ul>
				</div>
				{row[0].state === 1 && (
					<form onSubmit={handleSubmit(onSubmit)}>
						<textarea id="text" placeholder="Введите ответ..." rows={5} {...register("text", { required: "Это поле обязательно для заполнения", minLength: 3 })} className={styles.input} />
						<p className={styles.error}>{errors.text?.message}</p>
						<input type="file" id="file" {...register("file")} />
						<p className={styles.error}>{errors.file?.message}</p>

						<Button color="secondary" variant="contained" type="submit">
							Ответить
						</Button>
					</form>
				)}
				<Button color="secondary" onClick={onClose} sx={{ mt: "10px" }}>
					Закрыть
				</Button>
				<Snackbar open={IsOpenSnackbar} autoHideDuration={3000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="success">
						Точка успешно изменена.
					</Alert>
				</Snackbar>
			</Box>
		</Modal>,
		node
	)
}
