import React from "react"
import styles from "./editmailing.module.css"
import { SubmitHandler, useForm } from "react-hook-form"
import { Alert, Box, Button, Modal, Snackbar, TextField, Typography } from "@mui/material"
import ReactDOM from "react-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { EditMailAction, IMailing } from "../../store/mailingReducer"

interface IInputs {
	title: string
	url: string
	text: string
	file?: string
}

interface IEditMailingProps {
	open: boolean
	mailID: number
	onClose: () => void
}

export function EditMailing({ open, mailID, onClose }: IEditMailingProps) {
	const [IsOpenSnackbar, setOpenSnackbar] = React.useState(false)
	let row: IMailing[] = useSelector((state: { mailing: { data: IMailing[] } }) => state.mailing.data.filter((point: IMailing) => point.id === mailID))
	const dispatch = useDispatch()

	const schema = yup
		.object({
			title: yup.string().min(3, "Минимальная длина 3 символа").required("Название является обязательным"),
			url: yup.string().required("Ссылка является обязательной"),
			text: yup.string().min(5, "Минимальная длина 5 символов").required("Текст является обязательным"),
			file: yup.string(),
		})
		.required()

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IInputs>({
		resolver: yupResolver(schema),
		defaultValues: {
			title: row[0].title,
			url: row[0].url,
			text: row[0].content,
		},
	})

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return
		}
		onClose()
	}

	const onSubmit: SubmitHandler<IInputs> = () => {
		setOpenSnackbar(true)
		onClose()
		const data = {
			id: mailID,
			title: getValues("title"),
			content: getValues("text"),
			photo: String(getValues("file")),
			state: 0,
		}
		dispatch(EditMailAction(data))
	}

	const node = document.querySelector(`#modal_root`)
	if (!node) return null

	return ReactDOM.createPortal(
		<Modal open={open} onClose={onClose}>
			<Box className={styles.formWrapper}>
				<Typography variant="h4" component="h1" sx={{ mb: "20px" }}>
					Изменить рассылку
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<TextField size="small" id="title" label="Название рассылки" variant="outlined" {...register("title", { required: "Это поле обязательно для заполнения", minLength: 3 })} className={styles.input} />
					<p className={styles.error}>{errors.title?.message}</p>
					<TextField size="small" id="url" label="URL товара" variant="outlined" {...register("url", { required: "Это поле обязательно для заполнения" })} className={styles.input} />
					<p className={styles.error}>{errors.url?.message}</p>
					<textarea id="text" placeholder="Текст..." rows={5} {...register("text", { required: "Это поле обязательно для заполнения", minLength: 3 })} className={styles.input} />
					<p className={styles.error}>{errors.text?.message}</p>
					<input type="file" id="file" {...register("file")} />
					<p className={styles.error}>{errors.file?.message}</p>

					<Button color="secondary" variant="contained" type="submit">
						Изменить
					</Button>
				</form>
				<Button color="secondary" onClick={onClose} sx={{ mt: "10px" }}>
					Закрыть
				</Button>
				<Snackbar open={IsOpenSnackbar} autoHideDuration={3000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="success">
						Рассылка успешно изменена.
					</Alert>
				</Snackbar>
			</Box>
		</Modal>,
		node
	)
}
