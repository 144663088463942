import React from "react"
import styles from "./footer.module.css"
import { Link } from "react-router-dom"

export function Footer() {
	return (
		<footer className={styles.footerWrapper}>
			<p>© 2023 ООО “Бьюти Технолоджи” ВСЕ ПРАВА ЗАЩИЩЕНЫ</p>
			<Link to="/policy">ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</Link>
		</footer>
	)
}
