import * as React from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import { visuallyHidden } from "@mui/utils"
import styles from "./helpTable.module.css"
import { EditRequest } from "../EditRequest"
import { IHelp } from "../../store/helpReducer"
import { useSelector } from "react-redux"
import { HelpModal } from "../HelpModal"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { MyTooltip } from "../../MyTooltip"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

interface HeadCell {
	disablePadding: boolean
	id: keyof IHelp
	label: string
	numeric: boolean
	width?: number
}

const headCells: readonly HeadCell[] = [
	{
		id: "date",
		numeric: true,
		disablePadding: true,
		label: "Дата",
		width: 80,
	},
	{
		id: "theme",
		numeric: false,
		disablePadding: false,
		label: "Тема",
		width: 90,
	},
	{
		id: "text",
		numeric: false,
		disablePadding: false,
		label: "Обращение",
		width: 350,
	},
	{
		id: "state",
		numeric: true,
		disablePadding: false,
		label: "Статус",
		width: 80,
	},
]

interface EnhancedTableProps {
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IHelp) => void
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
	order: Order
	orderBy: string
	rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props
	const createSortHandler = (property: keyof IHelp) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow className={styles.head}>
				{headCells.map(headCell => (
					<TableCell width={headCell.width} key={headCell.id} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export function HelpTable() {
	const [order, setOrder] = React.useState<Order>("desc")
	const [orderBy, setOrderBy] = React.useState<keyof IHelp>("id")
	const [selected, setSelected] = React.useState<readonly string[]>([])
	const [page, setPage] = React.useState(0)
	const [pages, setPages] = React.useState(1)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [isModalOpen, setIsModalOpen] = React.useState(false)
	const [questionID, setQuestionID] = React.useState(0)
	let rows: IHelp[] = useSelector((state: { help: { data: IHelp[] } }) => state.help.data)

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IHelp) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map(n => n.text)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleSelectPage = (event: SelectChangeEvent) => {
		getPages()
		const pageNumber = Number(event.target.value)
		setPage(pageNumber)
	}

	function getPages() {
		const pages = Math.ceil(rows.length / rowsPerPage)
		setPages(pages)
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	function limitStr(str: string, n: number, symb?: string) {
		if (!n && !symb) return str
		if (str.length < n) return str
		symb = symb || "..."
		return str.substr(0, n - symb.length) + symb
	}

	function helpModal(postID: number) {
		setQuestionID(postID)
		setIsModalOpen(true)
	}

	function addZero(num: number) {
		var str = num.toString()
		return str.length === 1 ? "0" + str : str
	}

	function getState(state: number) {
		let textState = ""
		switch (state) {
			case 0:
				textState = "Закрыто"
				break
			case 1:
				textState = "Открыто"
				break
			case 2:
				textState = "Новый ответ"
				break
		}
		return textState
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
	// eslint-disable-next-line
	const visibleRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage, rows])
	const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)

	return (
		<Box sx={{ margin: "0 auto", mt: "20px" }}>
			<Paper sx={{ margin: "0 auto", mb: 2 }} className={styles.formWrapper}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}>
						<EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
						<TableBody>
							{visibleRows.length > 0 ? (
								visibleRows.map((row, index) => {
									const isItemSelected = isSelected(row.text)
									const time = new Date(row.date)
									const date = addZero(time.getDate()) + "." + addZero(time.getMonth()) + "." + addZero(time.getFullYear()) + " " + addZero(time.getHours()) + ":" + addZero(time.getMinutes())

									return (
										<TableRow
											hover
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											className={row.state === 2 ? styles.newMessage : ""}
											sx={{ cursor: "pointer" }}
											onClick={() => {
												helpModal(row.id)
											}}
										>
											<TableCell>{date}</TableCell>
											<TableCell>{row.theme}</TableCell>
											<TableCell>
												<MyTooltip title={row.text}>
													<span>{limitStr(row.text, 70)}</span>
												</MyTooltip>
											</TableCell>
											<TableCell>{getState(row.state)}</TableCell>
										</TableRow>
									)
								})
							) : (
								<TableCell colSpan={5}>
									<p className={styles.noPlaces}>Вопросы отсутствуют</p>
								</TableCell>
							)}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<div className={styles.paginationWrapper}>
					<FormControl sx={{ width: "160px" }} size="small">
						<InputLabel id="page-select">Страница</InputLabel>
						<Select labelId="page-select" id="page-select" value={String(page)} label="Страница" onChange={handleSelectPage} onOpen={getPages}>
							{[...Array(pages)].map((el, i) => {
								return <MenuItem value={i}>{i + 1} страница</MenuItem>
							})}
						</Select>
					</FormControl>
					<TablePagination showFirstButton={true} showLastButton={true} labelRowsPerPage="КОЛ-ВО ЗАПИСЕЙ НА СТРАНИЦЕ" rowsPerPageOptions={[10, 30, 50, 100, rows.length]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
				</div>
			</Paper>
			{isModalOpen && (
				<HelpModal
					id={questionID}
					onClose={() => {
						setIsModalOpen(false)
					}}
					open={isModalOpen}
				/>
			)}
			{isEditModalOpen && (
				<EditRequest
					onClose={() => {
						setIsEditModalOpen(false)
					}}
					open={isEditModalOpen}
				/>
			)}
		</Box>
	)
}
