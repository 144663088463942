import { Reducer } from "redux"

const defaultState = {
	bonusValue: 500,
}

export type bonusState = {
	bonusValue: number
}

const SET_BONUS = "SET_BONUS"

export const bonusReducer: Reducer<bonusState> = (state = defaultState, action) => {
	switch (action.type) {
		case SET_BONUS:
			return { ...state, bonusValue: action.payload }
		default:
			return state
	}
}

export const SetBonusAction = (payload: number) => ({ type: SET_BONUS, payload })
