import React from "react"
import { styled } from "@mui/material/styles"
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material"

export const MyTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#333",
		color: "#fff",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
	},
}))
