import { Reducer } from "redux"

export interface IMessages {
	name: string
	message: string
	date: number
}

export interface IHelp {
	id: number
	date: number
	theme: string
	text: string
	file: string
	state: number
	dialog: IMessages[]
}

export type helpState = {
	data: IHelp[]
}

const defaultState: helpState = {
	data: [
		{ id: 1686057073591, date: 1676057173598, theme: "Как купить", text: "Далеко-далеко за словесными горами ", file: "", state: 2, dialog: [{ name: "Администратор", message: "Всё окей, всё починим", date: 1686257073592 }] },
		{ id: 1686257073592, date: 1676257273598, theme: "Как купить", text: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Взобравшись семантика своего правилами родного текстами коварных города повстречался, то пунктуация ее сбить но взгляд!", file: "", state: 1, dialog: [{ name: "Администратор", message: "Всё окей, всё починим", date: 1686257073592 }] },
		{ id: 1686557073593, date: 1676557373598, theme: "Как купить", text: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Взобравшись семантика своего правилами родного текстами коварных города повстречался, то пунктуация ее сбить но взгляд!", file: "", state: 0, dialog: [{ name: "Администратор", message: "Всё окей, всё починим", date: 1686257073592 }] },
		{ id: 1686757073594, date: 1676757473598, theme: "Как купить", text: "Далеко-далеко за словесными горами в стране гласных и согласных живут рыбные тексты. Взобравшись семантика своего правилами родного текстами коварных города повстречался, то пунктуация ее сбить но взгляд!", file: "", state: 1, dialog: [{ name: "Администратор", message: "Всё окей, всё починим", date: 1686257073592 }] },
	],
}

const ADD_HELP = "ADD_HELP"
const ADD_MANY_HELP = "ADD_MANY_HELP"

export const helpReducer: Reducer = (state: helpState = defaultState, action) => {
	switch (action.type) {
		case ADD_HELP:
			return { ...state, data: [...state.data, action.payload] }
		case ADD_MANY_HELP:
			return { ...state, data: [...state.data, ...action.payload] }
		default:
			return state
	}
}

export const AddHelpAction = (payload: IHelp) => ({ type: ADD_HELP, payload })
export const AddManyHelpAction = (payload: IHelp[]) => ({ type: ADD_MANY_HELP, payload })
