import { Reducer } from "redux"

export interface IAccess {
	id: number
	date: number
	money: number
	before: number
	receipt: string
	auto: string
}

export type accessState = {
	data: IAccess[]
}

const defaultState: accessState = {
	data: [
		{ id: 1696057073598, date: 1696057073598, money: 1990, before: 1696057073598, receipt: "https://yandex.ru", auto: "Выключен" },
		{ id: 1696127176598, date: 1696127176598, money: 1990, before: 1696127176598, receipt: "https://yandex.ru", auto: "Выключен" },
		{ id: 1696153173438, date: 1696153173438, money: 1990, before: 1696153173438, receipt: "https://yandex.ru", auto: "Выключен" },
		{ id: 1696155173598, date: 1696155173598, money: 1990, before: 1696155173598, receipt: "https://yandex.ru", auto: "Выключен" },
		{ id: 1696157173598, date: 1696157173598, money: 1990, before: 1696157173598, receipt: "https://yandex.ru", auto: "Выключен" },
		{ id: 1696159178298, date: 1696159178298, money: 1990, before: 1696159178298, receipt: "https://yandex.ru", auto: "Выключен" },
	],
}

const ADD_ACCESS = "ADD_ACCESS"
const ADD_MANY_ACCESS = "ADD_MANY_ACCESS"

export const accessReducer: Reducer = (state: accessState = defaultState, action) => {
	switch (action.type) {
		case ADD_MANY_ACCESS:
			return { ...state, data: [...state.data, ...action.payload] }
		case ADD_ACCESS:
			return { ...state, data: [...state.data, action.payload] }
		default:
			return state
	}
}

export const AddAccessAction = (payload: IAccess) => ({ type: ADD_ACCESS, payload })
export const AddManyAccessAction = (payload: IAccess[]) => ({ type: ADD_MANY_ACCESS, payload })
