import React from "react"
import styles from "./reviewmodal.module.css"
import ReactDOM from "react-dom"
import { Alert, Box, Button, Modal, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { IReview } from "../../store/reviewsReducer"

interface IQuestionModal {
	id: number
	open: boolean
	onClose: () => void
}

export function ReviewModal({ id, open, onClose }: IQuestionModal) {
	let row: IReview[] = useSelector((state: { reviews: { data: IReview[] } }) => state.reviews.data.filter((point: IReview) => point.id === id))

	function addZero(num: number) {
		var str = num.toString()
		return str.length === 1 ? "0" + str : str
	}

	const time = new Date(row[0].date)
	const date = addZero(time.getDate()) + "." + addZero(time.getMonth()) + "." + addZero(time.getFullYear()) + " " + addZero(time.getHours()) + ":" + addZero(time.getMinutes())

	const node = document.querySelector(`#modal_root`)
	if (!node) return null
	const link = "https://t.me/" + row[0].account

	return ReactDOM.createPortal(
		<Modal open={open} onClose={onClose}>
			<Box className={styles.formWrapper}>
				<Typography variant="h6" component="h1" sx={{ mb: "10px" }}>
					Отзыв
				</Typography>
				<div className={styles.dataWrapper}>
					<div className={styles.contentWrapper}>
						<div>
							{" "}
							<p className={styles.data}>{row[0].name}</p>
							<p className={styles.data}>{date}</p>
							<p className={styles.data}>
								<a href={link} target="_blank" rel="noreferrer">
									@{row[0].account}
								</a>
							</p>
							<p className={styles.data}>{row[0].phone}</p>
						</div>
						{row[0].photo && <img src={row[0].photo} className={styles.questionImg} alt={row[0].name} />}
					</div>
					<p className={styles.reviewText}>{row[0].review}</p>
				</div>
				<Button color="secondary" onClick={onClose} sx={{ mt: "10px" }}>
					Закрыть
				</Button>
			</Box>
		</Modal>,
		node
	)
}
