import React from "react"
import styles from "./information.module.css"
import { List, ListItem } from "@mui/material"

export function Information() {
	return (
		<div className={styles.wrapper}>
			<List sx={{ fontSize: "18px" }}>
				<ListItem className={styles.listItem}>1. Интегрируйте ваш qr код в бонусный, подарочный или акционный флаер, визитку, либо другую полиграфию</ListItem>
				<ListItem className={styles.listItem}>2. Распечатайте разработанный макет</ListItem>
				<ListItem className={styles.listItem}>3. Получившийся флаер, визитку или другую полиграфию прикладывайте к продаваемой продукции или услуге</ListItem>
				<ListItem className={styles.listItem}>4. Отслеживайте и обрабатывайте новые заявки от клиентов в личном кабинете</ListItem>
			</List>
			<p className={styles.important}>* ЕСЛИ РАЗМЕР БОНУСА РАВЕН 0 РУБЛЕЙ, ТО КЛИЕНТУ НЕ БУДЕТ ПРЕДЛАГАТЬСЯ ПООЩРЕНИЕ ЗА ОТЗЫВ В БОТЕ. </p>
			<p className={styles.important}>** ЕСЛИ РАЗМЕР БОНУСА БОЛЬШЕ 0 РУБЛЕЙ, ТО СИСТЕМА РАБОТАЕТ В РЕЖИМЕ МОТИВАЦИИ КЛИЕНТА. ПРИ ЭТОМ КЛИЕНТУ В БОТЕ БУДЕТ СООБЩАТЬСЯ, ЧТО ПОСЛЕ ОТПРАВКИ ОТЗЫВА И ЕГО ПОСЛЕДУЮЩЕЙ ПРОВЕРКИ КЛИЕНТ ПОЛУЧИТ ВЫПЛАТУ БОНУСА НА БАЛАНС ТЕЛЕФОНА. </p>
			<p className={styles.important}>*** ОПЛАТА БОНУСА ПРОИСХОДИТ ЗА СЧЕТ СОБСТВЕННЫХ СРЕДСТВ ПОЛЬЗОВАТЕЛЕЙ ДАННОГО СЕРВИСА.</p>
		</div>
	)
}
