import React, { useState } from "react"
import styles from "./addrequest.module.css"
import { SubmitHandler, useForm } from "react-hook-form"
import { Alert, Box, Button, Modal, Snackbar, TextField, Typography } from "@mui/material"
import ReactDOM from "react-dom"
import { useDispatch } from "react-redux"
import { AddHelpAction } from "../../store/helpReducer"

interface IInputs {
	theme: string
	question: string
	file: string
}

interface IAddRequest {
	open: boolean
	onClose: () => void
}

export function AddRequest({ open, onClose }: IAddRequest) {
	const [IsOpenSnackbar, setOpenSnackbar] = useState(false)
	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IInputs>()

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return
		}
		onClose()
	}

	const onSubmit: SubmitHandler<IInputs> = () => {
		setOpenSnackbar(true)
		onClose()
		const data = {
			id: Date.now(),
			date: Date.now(),
			theme: getValues("theme"),
			text: getValues("question"),
			file: String(getValues("file")),
			state: 1,
			dialog: [],
		}
		dispatch(AddHelpAction(data))
	}

	const node = document.querySelector(`#modal_root`)
	if (!node) return null

	return ReactDOM.createPortal(
		<Modal open={open} onClose={onClose}>
			<Box className={styles.formWrapper}>
				<Typography variant="h4" component="h1" sx={{ mb: "20px" }}>
					Создать обращение
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<TextField size="small" id="theme" label="Тема" variant="outlined" {...register("theme", { required: "Это поле обязательно для заполнения" })} className={styles.input} />
					<p className={styles.error}>{errors.theme?.message}</p>
					<TextField size="small" autoComplete="question" id="question" label="Вопрос" variant="outlined" {...register("question", { required: "Это поле обязательно для заполнения" })} className={styles.input} />
					<p className={styles.error}>{errors.question?.message}</p>
					<input type="file" id="file" {...register("file")} />
					<p className={styles.error}>{errors.file?.message}</p>

					<Button color="secondary" variant="contained" type="submit" onClick={() => handleSubmit}>
						Создать
					</Button>
				</form>
				<Button color="secondary" onClick={onClose} sx={{ mt: "10px" }}>
					Закрыть
				</Button>
				<Snackbar open={IsOpenSnackbar} autoHideDuration={3000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="success">
						Точка успешно добавлена.
					</Alert>
				</Snackbar>
			</Box>
		</Modal>,
		node
	)
}
