import React from "react"
import styles from "./App.module.css"
import { ThemeProvider, createTheme } from "@mui/material"
import { QrCode } from "./QrCode"
import { Header } from "./Header"
import { Footer } from "./Footer"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Layout } from "./Layout"
import { NoMatch } from "./NoMatch"
import { Questions } from "./Questions"
import { Reviews } from "./Reviews"
import { Places } from "./Places"
import { ChangePassword } from "./ChangePassword"
import { Policy } from "./Policy"
import { Mailing } from "./Mailing"
import { Help } from "./Help"
import { Access } from "./Access"
import { Provider } from "react-redux"
import { store } from "./store"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

const theme = createTheme({
	palette: {
		primary: {
			light: "#6573c3",
			main: "#3f51b5",
			dark: "#2c387e",
		},
		secondary: {
			light: "#33bfff",
			main: "#00b0ff",
			dark: "#007bb2",
			contrastText: "#fff",
		},
	},
})

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<ThemeProvider theme={theme}>
						<div className={styles.wrapper}>
							<Header />
							<Routes>
								<Route path="/" element={<Layout />}>
									<Route index={true} element={<Navigate to="/qrcode" />} />
									<Route path="/qrcode" element={<QrCode />} />
									<Route path="/questions" element={<Questions />} />
									<Route path="/reviews" element={<Reviews />} />
									<Route path="/change-pass" element={<ChangePassword />} />
									<Route path="/policy" element={<Policy />} />
									<Route path="/places" element={<Places />} />
									<Route path="/mailing" element={<Mailing />} />
									<Route path="/help" element={<Help />} />
									<Route path="/access" element={<Access />} />
									<Route path="*" element={<NoMatch />} />
								</Route>
							</Routes>
						</div>
						<Footer />
					</ThemeProvider>
				</LocalizationProvider>
			</BrowserRouter>
		</Provider>
	)
}

export default App
