import React from "react"
import styles from "./questions.module.css"
import { QuestionTable } from "./QuestionTable"

export function Questions() {
	return (
		<div className={styles.wrapper}>
			<QuestionTable />
		</div>
	)
}
