import React from "react"
import styles from "./code.module.css"
import { Alert, Button, Snackbar, TextField } from "@mui/material"
import { useForm } from "react-hook-form"
import { SubmitHandler } from "react-hook-form/dist/types"
import { useDispatch, useSelector } from "react-redux"
import { SetBonusAction, bonusState } from "../../store/bonusReducer"

export function Code() {
	const bonusValue = useSelector((state: { bonus: bonusState }) => state.bonus.bonusValue)
	const [open, setOpen] = React.useState(false)
	const dispatch = useDispatch()

	const setCash = (value: number) => {
		dispatch(SetBonusAction(value))
	}

	interface IFormInput {
		bonus: number
	}

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({ defaultValues: { bonus: bonusValue } })

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return
		}

		setOpen(false)
	}

	const onSubmit: SubmitHandler<IFormInput> = () => {
		setOpen(true)
		setCash(Number(getValues("bonus")))
	}

	return (
		<div className={styles.wrapper}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.inputWrapper}>
					<p>Размер бонуса</p>
					<TextField id="bonus" label="" variant="standard" {...register("bonus", { required: "Это поле обязательно для заполнения", pattern: { value: /^[ 0-9]+$/, message: "Используйте цифры" }, min: { value: 0, message: "Минимальное значение - 0" }, max: { value: 999, message: "Максимальное значение - 999" } })} />
					<p>руб.</p>
					<Button variant="contained" color="secondary" type="submit">
						Установить
					</Button>
				</div>
				<p className={styles.error}>{errors.bonus?.message}</p>
			</form>
			<img src="image.png" alt="QR Code" />
			<Button variant="contained" color="secondary" size="large">
				Скачать QR Code
			</Button>
			<Button variant="contained" color="secondary" size="large">
				Отправить QR Code на почту
			</Button>
			<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success">
					Размер бонуса изменён
				</Alert>
			</Snackbar>
		</div>
	)
}
