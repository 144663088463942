import { createStore, combineReducers } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { bonusReducer } from "./bonusReducer"
import { placesReducer } from "./placesReducer"
import { questionsReducer } from "./questionsReducer"
import { mailingReducer } from "./mailingReducer"
import { helpReducer } from "./helpReducer"
import { accessReducer } from "./accessReducer"
import { reviewsReducer } from "./reviewsReducer"

const rootReducer = combineReducers({ bonus: bonusReducer, places: placesReducer, questions: questionsReducer, mailing: mailingReducer, access: accessReducer, help: helpReducer, reviews: reviewsReducer })

export const store = createStore(rootReducer, composeWithDevTools())
